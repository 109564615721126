<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
    page() {
        return {
            title: this.title,
            meta: [{ name: "description" }],
        };
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Starter page",
            items: [
                {
                    text: "Utility",
                    href: "/",
                },
                {
                    text: "Starter page",
                    active: true,
                },
            ],
            loading: false,
            appid: "",
            userinfo: [],
            free: {},
            swiper: [],
            micoappsaletab: "charge",
            swipertype: [
                {
                    key: "image",
                    name: "图片"
                }
            ],
            progress: 0,
            loadingtext: "加载中..."
        };
    },
    mounted() {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        this.appid = this.$route.params.appid;
        this.title = "首页轮播图";
        this.items = [
            {
                text: this.$t("menuitems.micoapp.text"),
                href: "/",
            },
            {
                text: this.$t("menuitems.icp.micoapp.list"),
                href: "/micoapp",
            },
            {
                text: this.$t("menuitems.icp.micoapp.info"),
                href: `/micoapp/${this.appid}`,
            },
            {
                text: "首页轮播图",
                active: true,
            },
        ];
        this.getmicoappmenu();
    },
    methods: {
        getmicoappmenu() {
            var that = this;
            that.loading = true;
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.swiper",
                        appid: that.$route.params.appid,
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    that.swiper = response.data.data;
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$notify.error({
                        title: '请求错误',
                        message: error
                    });
                });
        },
        save_micoappsetting(data) {
            var that = this;
            that.loading = true;
            console.log(data)
            that.$axios
                .post(
                    "https://api.aicoiot.com/micoappapi.php",
                    {
                        action: "micoapp.saveswiper",
                        appid: that.$route.params.appid,
                        id: data.id,
                        datas: data
                    },
                    {
                        headers: { Openid: that.userinfo.openid },
                    }
                )
                .then(function (response) {
                    that.loading = false;
                    if (response.data.status == 200) {
                        that.$message.success({
                            message: "保存成功"
                        });
                        that.getmicoappmenu();
                    } else {
                        that.$message.error({
                            message: response.data.message
                        });
                    }
                })
                .catch(function (error) {
                    that.loading = false;
                    that.$notify.error({
                        title: '请求错误',
                        message: error
                    });
                });
        },
        addrow_charge_sale() {
            this.swiper.push({ "id": this.swiper.length + 1, "index": 1, "type": "image", "imgUrl": null })
        },
        delete_micoappsetting(rows) {
            var that = this;
            that.$confirm('此操作将永久此图片, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.loading = true;
                that.$axios
                    .post(
                        "https://api.aicoiot.com/micoappapi.php",
                        {
                            action: "micoapp.deleteswiper",
                            appid: that.$route.params.appid,
                            id: rows.id
                        },
                        {
                            headers: { Openid: that.userinfo.openid },
                        }
                    )
                    .then(function (response) {
                        that.loading = false;
                        if (response.data.status == 200) {
                            that.$message.success({
                                message: "删除成功"
                            });
                            that.getmicoappmenu();
                        } else {
                            that.$message.error({
                                message: response.data.message
                            });
                        }
                    })
                    .catch(function (error) {
                        that.loading = false;
                        that.$notify.error({
                            title: '请求错误',
                            message: error
                        });
                    });
            }).catch(() => {

            });

        },
        uploadimg(file, fileList) {
            console.log(file, fileList);
        },
        handleAvatarSuccess(response, file, row) {
            this.loading = false;
            this.progress = 0;
            // row.imgUrl = URL.createObjectURL(file.raw); // 更新图片URL
            row.imgUrl = response.url;
            // console.log(row);
            // console.log(response);
            this.showNotification('上传成功', '图片上传成功', 'success');
        },
        handleError() {
            this.loading = false;
            this.progress = 0;
            this.showNotification('上传失败', '图片上传失败', 'error');
        },
        handleProgress(event) {
            this.progress = Math.round((event.percent || 0));
            this.loadingtext = `正在上传: ${this.progress}%`;
            if (this.progress == 100) {
                this.loadingtext = "加载中...";
            }
        },
        beforeAvatarUpload(file) {
            this.loading = true;
            this.progress = 0;
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.showNotification('错误', '上传图片格式必须是 JPG 或 PNG!', 'error');
                this.loading = false;
                return false;
            }
            if (!isLt2M) {
                this.showNotification('错误', '上传图片大小不能超过 2MB!', 'error');
                this.loading = false;
                return false;
            }
            return true;
        },
        showNotification(title, message, type) {
            this.$message({
                message: `${message}`,
                type: `${type}`
            });
            // this.$notify[type]({
            //     title: title,
            //     message: message
            // });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row" v-loading="loading" :element-loading-text="loadingtext"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div style="text-align: center;">
                            <!-- <el-button @click="save_micoappsetting" type="primary">保存</el-button> -->
                            <el-button @click="addrow_charge_sale" type="success">添加</el-button>
                            <el-alert title="图片推荐尺寸700x1000px" type="success" :closable="false">
                            </el-alert>
                        </div>
                        <el-table :data="swiper" stripe style="width: 100%"
                            :default-sort="{ prop: 'id', order: 'descending' }">
                            <el-table-column prop="id" label="序号" width="80" sortable></el-table-column>
                            <el-table-column label="排序" width="80">
                                <template slot-scope="scope">
                                    <el-input type="number" v-model="scope.row.index"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="类型">
                                <template slot-scope="scope">
                                    <el-select v-model="scope.row.type" placeholder="请选择">
                                        <el-option v-for="item in swipertype" :key="item.key" :label="item.name"
                                            :value="item.key"></el-option>
                                    </el-select>
                                </template>
                            </el-table-column>
                            <el-table-column label="图片">
                                <template slot-scope="scope">
                                    <el-upload class="avatar-uploader" action="https://api.aicoiot.com/uploadfile.php"
                                        :show-file-list="false"
                                        :on-success="(response, file) => handleAvatarSuccess(response, file, scope.row)"
                                        :on-error="handleError" :on-progress="handleProgress"
                                        :before-upload="beforeAvatarUpload" :disabled="loading">
                                        <img v-if="scope.row.imgUrl" :src="scope.row.imgUrl" class="avatar" />
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </template>
                            </el-table-column>
                            <el-table-column fixed="right" label="操作">
                                <template slot-scope="scope">
                                    <el-button @click="save_micoappsetting(scope.row)" type="primary"
                                        size="small">保存</el-button>
                                    <el-button @click="delete_micoappsetting(scope.row)" type="danger" size="small">
                                        移除
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<style>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    /* height: 178px; */
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
